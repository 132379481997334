<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.log_webhook_delivery") }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div>
        <v-simple-table
          fixed-header
          class="table-padding-2"
          height="calc(100vh - 250px)"
        >
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.time") }}
                </th>
                <th role="columnheader" class="text-center" style="width: 90%">
                  {{ $t("labels.data") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in items"
                :key="item.id"
                style="vertical-align: top"
              >
                <td class="text-center">
                  {{ formatDateTime(item.created_at) }}
                </td>
                <td>
                  <div style="white-space: pre-wrap">
                    {{
                      JSON.stringify(
                        JSON.parse(item.request_data),
                        undefined,
                        2
                      )
                    }}
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "LogOrderDeliveryWebhook",
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    items: [],
  }),
  mounted() {
    this.getList();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    async getList() {
      const url = this.order.id_pos
        ? "/pos/v1/order-online-get-delivery-webhook-log"
        : "/goods-issue-detail/v1/get-order-delivery-webhook-log";
      const { data } = await httpClient.post(url, { id_order: this.order.id });
      this.items = [...data];
    },
  },
};
</script>

<style scoped></style>
